<template>
  <v-container>
    <base-loading v-if="loading.loading" />

    <v-card v-else class="ma-3 px-5 py-5">
      <span class="text-h3">Cronograma de atividades</span>

      <div class="content">
        <TableGraph
          :headers="headers"
          :items="desserts"
          item-value="name"
          class="elevation-1"
        ></TableGraph>
      </div>
      <v-divider></v-divider>
    </v-card>
  </v-container>
</template>

<script>
import moment from 'moment'
import TableGraph from '../../../components/base/TableGraph.vue'
import farmProjectService from '../../../services/api/farmProject'
export default {
  name: 'VisitSchedule',

  components: { TableGraph },

  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: {
        loading: false,
      },

      headers: [
        {
          text: 'Mês/ Ano',
          align: 'start',
          sortable: false,
          value: 'month',
        },
        {
          text: 'Realizadas',
          align: 'start',
          sortable: false,
          value: 'accomplished',
        },
        {
          text: 'Visitas',
          align: 'start',
          sortable: false,
          value: 'visits',
        },
      ],

      desserts: [],
    }
  },

  created: async function () {
    this.loading.loading = true
    await this.index()

    this.loading.loading = false
  },

  methods: {
    async index() {
      try {
        const { data } = await farmProjectService.dashboardVisit(this.id)

        const startProjectDate = new Date(
          moment(data.projectPlanning.startProject),
        )
        const endProjectDate = new Date(data.projectPlanning.finalProject)

        const months = []

        while (startProjectDate < endProjectDate) {
          const month = startProjectDate.toLocaleString('pt-BR', {
            month: 'long',
          })

          const year = startProjectDate.getFullYear()

          const monthYear = `${month}/${year}`

          const visits = data.farms.filter(farm => {
            const startDate = new Date(farm.startDate)

            if (startProjectDate <= startDate) {
              return false
            }

            if (farm.leaveDate) {
              const leaveDate = new Date(farm.leaveDate)
              if (leaveDate < startProjectDate) {
                return false
              } else {
                return startDate <= startDate && leaveDate >= startDate
              }
            } else if (farm.endDate) {
              const endDate = new Date(farm.endDate)
              if (endDate < startProjectDate) {
                return false
              } else {
                return startDate <= startDate && endDate >= startDate
              }
            } else {
              return startDate <= startProjectDate
            }
          }).length

          // Visitas realizadas
          let accomplished = 0
          data.farms.forEach(element => {
            element.visitas.forEach(visita => {
              const visitaDate = new Date(visita?.dataAtendimento)
              if (
                visitaDate.getMonth() === startProjectDate.getMonth() &&
                visitaDate.getFullYear() === startProjectDate.getFullYear()
              ) {
                accomplished++
              }
            })
          })

          months.push({
            month: monthYear,
            accomplished: accomplished,
            visits: visits,
          })

          startProjectDate.setMonth(startProjectDate.getMonth() + 1)
        }

        this.desserts = months
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao carregar os dados do dashboard: ' + error.message,
        })
      } finally {
        this.loading.loading = false
      }
    },
  },
}
</script>
