<template>
  <v-container>
    <span class="text-h2">Controle de visitas</span>
    <base-loading v-if="loading.loading" />

    <v-card v-else class="ma-1 px-5 py-5">
      <span class="subtitle-1">DATA DAS VISITAS T1</span>
      <div v-if="desserts['partial1']" class="content">
        <TableGraph
          :items="desserts['partial1']"
          :headers="headers['partial1']"
          class="elevation-1"
          :disable-pagination="false"
          :hide-defaultfooter="false"
        />
      </div>
      <div v-else>
        <span class="text-h5 grey--text"
          >O relatório não atingiu a data parcial 1</span
        >
      </div>

      <v-divider></v-divider>

      <span class="subtitle-1">DATA DAS VISITAS T2</span>
      <div v-if="desserts['partial2']" class="content">
        <TableGraph
          :items="desserts['partial2']"
          :headers="headers['partial2']"
          class="elevation-1"
          :disable-pagination="false"
          :hide-defaultfooter="false"
        />
      </div>
      <div v-else>
        <span class="text-h5 grey--text"
          >O relatório não atingiu a data parcial 2</span
        >
      </div>

      <v-divider></v-divider>

      <span class="subtitle-1">DATA DAS VISITAS FINAL</span>
      <div v-if="desserts['final']" class="content">
        <TableGraph
          :items="desserts['final']"
          :headers="headers['final']"
          class="elevation-1"
          :disable-pagination="false"
          :hide-defaultfooter="false"
        />
      </div>
      <div v-else>
        <span class="text-h5 grey--text"
          >O relatório não atingiu a data parcial final</span
        >
      </div>
      <v-divider></v-divider>
    </v-card>
  </v-container>
</template>

<script>
import TableGraph from '../../../components/base/TableGraph.vue'
import farmProjectService from '../../../services/api/farmProject'
import { contentTable, headers } from './controle'
export default {
  name: 'VisitSchedule',

  components: { TableGraph },

  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: {
        loading: false,
      },

      headers: [],

      desserts: [],
    }
  },

  created: async function () {
    this.loading.loading = true
    await this.index()

    this.loading.loading = false
  },

  methods: {
    async index() {
      try {
        const { data } = await farmProjectService.dashboardControl(this.id)

        this.headers['partial1'] = headers(
          data.projectPlanning.startProject,
          data.projectPlanning.partial1,
        )

        this.headers['partial2'] = headers(
          data.projectPlanning.partial1,
          data.projectPlanning.partial2,
        )

        this.headers['final'] = headers(
          data.projectPlanning.partial2,
          data.projectPlanning.finalProject,
        )

        if (data.values?.partial1) {
          this.desserts['partial1'] = contentTable(
            data.projectPlanning.startProject,
            data.projectPlanning.partial1,
            data.values?.partial1 || [],
            this.headers['partial1'],
          )
        }

        if (data.values?.partial2) {
          this.desserts['partial2'] = contentTable(
            data.projectPlanning.partial1,
            data.projectPlanning.partial2,
            data.values?.partial2 || [],
            this.headers['partial2'],
          )
        }

        if (data.values?.final) {
          this.desserts['final'] = contentTable(
            data.projectPlanning.partial2,
            data.projectPlanning.finalProject,
            data.values?.final || [],
            this.headers['final'],
          )
        }
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao carregar os dados do dashboard: ' + error.message,
        })
      } finally {
        this.loading.loading = false
      }
    },
  },
}
</script>
