import moment from 'moment'

const staticHeader = [
  {
    text: 'Produtor',
    align: 'start',
    sortable: false,
    value: 'produtor',
  },
  {
    text: 'Status',
    align: 'start',
    sortable: false,
    value: 'status',
  },
]
const sumHeader = [
  'Nº de visitas Realizadas',
  'Nº de visitas Previstas',
  'Nº de visitas Pendentes',
  'Nº de visitas Pendentes acumulado',
]

export const headers = (initialDate, finalDate) => {
  const header = [...staticHeader]

  // get month and year from start and end date
  let startDate = new Date(moment(initialDate).subtract(5, 'days'))
  let endDate = new Date(moment(finalDate).subtract(1, 'month'))

  while (startDate <= endDate) {
    const month = startDate.toLocaleString('pt-BR', { month: 'short' })
    const year = startDate.getFullYear()

    header.push({
      text: `${month}/${year}`,
      align: 'start',
      sortable: false,
      value: `${month}/${year}`,
    })

    startDate = new Date(startDate.setMonth(startDate.getMonth() + 1))
  }

  header.push({
    text: 'Total',
    align: 'start',
    sortable: false,
    value: 'total',
  })

  return header
}

export const contentTable = (initialDate, finalDate, data, headers) => {
  let content = []

  let countPrevistas = 0

  const startDate = new Date(moment(initialDate))
  const endDate = new Date(moment(finalDate).subtract(1, 'month'))

  data.forEach(farm => {
    const produtor = farm.fazenda.produtor.Nome
    const status = farm.status ? 'Ativo' : 'Inativo'
    const count = farm.control.length

    // Count previstas
    if (farm.status) countPrevistas += 1

    let paramsItems = {}

    if (farm.control.length > 0) {
      farm.control.forEach(item => {
        const date = moment(item?.dataAtendimento).toDate()
        const monthFarm = date.toLocaleString('pt-BR', { month: 'short' })
        const yearFarm = date.getFullYear()

        if (date >= startDate && date <= endDate) {
          const param = `${monthFarm}/${yearFarm}`

          if (paramsItems[param]) {
            const old = paramsItems[param]

            paramsItems[param] = old + ',\n' + moment(date).format('DD/MM/YYYY')
          } else {
            paramsItems[param] = moment(date).format('DD/MM/YYYY')
          }
        }
      })
    }

    content.push({
      produtor: produtor,
      status: status,
      ...paramsItems,
      total: count,
    })
  })

  const tmp = {}

  headers.slice(2, headers.length - 1).forEach(item => {
    tmp[item.value] = content.reduce((acc, cur) => {
      if (cur[item.value]) {
        return acc + cur[item.value].split(',\n').length
      } else {
        return acc
      }
    }, 0)
  })

  content.push({
    produtor: sumHeader[0],
    status: '',
    ...tmp,
    total: Object.values(tmp).reduce((acc, cur) => acc + cur, 0),
    class: 'blue-row',
  })

  headers.slice(2, headers.length - 1).forEach(item => {
    tmp[item.value] = countPrevistas
  })

  content.push({
    produtor: sumHeader[1],
    status: '',
    ...tmp,
    total: Object.values(tmp).reduce((acc, cur) => {
      return acc + cur
    }, 0),
    class: 'blue-row',
  })

  headers.slice(2, headers.length - 1).forEach(item => {
    // Visita pendente acumulada, subtrai o total de visitas previstas do total de visitas realizadas
    tmp[item.value] =
      content[content.length - 1][item.value] -
      content[content.length - 2][item.value]
  })

  content.push({
    produtor: sumHeader[2],
    status: '',
    ...tmp,
    total: Object.values(tmp).reduce((acc, cur) => {
      return acc + cur
    }, 0),
    class: 'blue-row',
  })

  let oldDate = headers[2]?.value
  headers[2][content.length - 1] = content.length - 2
  headers.slice(3, headers.length - 1).forEach(item => {
    tmp[item.value] = content[content.length - 2][item.value] + tmp[oldDate]

    oldDate = item.value
  })

  content.push({
    produtor: sumHeader[3],
    status: '',
    ...tmp,
    total: Object.values(tmp).reduce((acc, cur) => acc + cur, 0),
    class: 'blue-row',
  })

  return content
}
