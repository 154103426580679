<template>
  <v-container>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          class="mx-2"
          fab
          dark
          small
          color="primary"
          v-on="on"
          @click="$router.go(-1)"
        >
          <v-icon dark> mdi-arrow-left </v-icon>
        </v-btn>
      </template>
      <span>Voltar</span>
    </v-tooltip>

    <v-tabs
      v-model="tabs"
      centered
      background-color="transparent"
      icons-and-text
    >
      <v-tabs-slider></v-tabs-slider>

      <v-tab @click="tabs = 0">
        Cronograma de atividades
        <v-icon>mdi-view-dashboard</v-icon>
      </v-tab>

      <v-tab @click="tabs = 1">
        CONTROLE DAS VISITAS
        <v-icon>mdi-google-analytics</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabs">
      <v-tab-item>
        <Visita :id="id" />
      </v-tab-item>

      <v-tab-item>
        <Controle :id="id" />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import Controle from './Controle.vue'
import Visita from './Visita.vue'

export default {
  components: { Visita, Controle },
  data() {
    return {
      tabs: 0,

      id: '',
    }
  },

  created: function () {
    this.id = this.$route.query.id
  },
}
</script>

<style>
.v-tab--active .v-icon {
  color: #003677 !important;
}
</style>
